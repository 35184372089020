import { render, staticRenderFns } from "./Result.vue?vue&type=template&id=626504e1&"
import script from "../js/result.js?vue&type=script&lang=js&"
export * from "../js/result.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/SysClass-Web-App/SysClass-Web-App/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('626504e1')) {
      api.createRecord('626504e1', component.options)
    } else {
      api.reload('626504e1', component.options)
    }
    module.hot.accept("./Result.vue?vue&type=template&id=626504e1&", function () {
      api.rerender('626504e1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/dynamic_generation/allocation/pages/Result.vue"
export default component.exports