import { mapActions } from 'vuex'

export default {
  data: () => ({
        loadingProgress: false,
    }),

    watch: {
     
    },

    created(){
        this.getData();
    },

    methods: {
      ...mapActions('dynamicGeneration', ['ActionDynamicAllocationViewResults']),
        
      getData(){
        
        this.loadingProgress = true;
        let id = this.$route.query.id;

        this.ActionDynamicAllocationViewResults({ id: id })
            .then((res) => {
                document.getElementById("content").innerHTML = res.bodyText;
            })
            .finally(() => {
                this.loadingProgress = false;
            });
      }
    
    },
  
  

}
